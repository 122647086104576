import React, { Component } from 'react'
import Parser from 'react-html-parser'
import { Navbar, NavDropdown, Button, Nav, Jumbotron, Container, Row, Col, Card, Modal, Form, Carousel } from 'react-bootstrap'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import StaticTextElement from './lib/static-lang'
import * as axios from 'axios'
import * as logo from './assets/ECS1.png'
import './App.css'

// const baseUrl = 'http://localhost:8080/cms/v1/endpoint/rqTXtgsksR/'
const baseUrl = 'https://api-cms.bitwald.com/cms/v1/endpoint/mMTWtZS4sP/'

// const mailEndpoint = 'http://localhost:8080/cms/v1/mail/rqTXtgsksR/'
const mailEndpoint = 'https://api-cms.bitwald.com/cms/v1/mail/mMTWtZS4sP/'

const imageUrl = 'https://api-cms.bitwald.com/cms/v1/static/'

class App extends Component {
  constructor(props) {
    super(props)
    this.ref1 = null
    this.ref2 = null
    this.reftop = null
    this.refDataProtection = null

    const lang = localStorage.getItem('adysoftware-locale') || 'de'

    this.state = {
      data: null,
      showModal: false,
      navextended: false,
      render: false,
      lang,
      submitDone: false,
      formMessage: null,
      formEvent: null,
      formName: null,
      formLegals: false,
      formEmail: null,
      showCourseList: false
    }

    this.componentWillMount = this.componentWillMount.bind(this)
    this.onSubmitForm = this.onSubmitForm.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.setInput = this.setInput.bind(this)
    this.main = this.main.bind(this)
    this.product1 = this.product1.bind(this)
    this.product2 = this.product2.bind(this)
    this.product3 = this.product3.bind(this)
    this.dataProtection = this.dataProtection.bind(this)
    this.imprint = this.imprint.bind(this)
    this.newZoomMeetings = this.newZoomMeetings.bind(this)

    this.staticText = (new StaticTextElement(lang || 'de')).staticText
  }

  async componentWillMount() {
    this.fetch()
  }

  componentDidMount() {
    this.setAnchor()
  }

  setAnchor(subject) {
    const hash = subject || window.location.hash

    if (hash) {
      window.href = '/'

      if (hash === '#leistungen') {
        setTimeout(() => {
          this.scrollTo(this.ref1)
        }, 400);
      }

      if (hash === '#referenzen') {
        setTimeout(() => {
          this.scrollTo(this.ref2)
        }, 400);
      }

    }
  }

  async fetch() {
    const apiEndpoint = baseUrl + this.state.lang
    const res = await axios.get(apiEndpoint)

    if (res && res.data) {
      const object = {}

      res.data.map((item) => object[item.uid] = item.content)
      this.setState({
        data: object
      }, this.forceUpdate)
    }
  }

  setLang(lang) {
    this.staticText = new StaticTextElement(lang).staticText
    localStorage.setItem('adysoftware-locale', lang)
    this.setState({
      lang
    }, this.fetch)
  }

  scrollTo(ref) {
    if (ref) {
      window.scrollTo(0, ref.offsetTop - 80);
    }
  }

  setInput(e) {
    e.preventDefault()
    const state = {}
    state[e.target.id] = (e.target.value)

    this.setState({
      ...state
    })
  }

  async onSubmitForm() {
    const apiEndpoint = mailEndpoint
    const options = {
      header: {
        'Content-Type': 'application/json'
      }
    }

    const body = {
      message: this.state.formMessage,
      name: this.state.formName,
      event: this.state.formEvent,
      mail: this.state.formMail,
      dataProtectionAccepted: this.state.formLegals === 'on' ? 'yes' : 'no'
    }

    try {
      await axios.post(apiEndpoint, body, options)

      this.setState({ submitDone: true })
    } catch (e) {
      console.log(e)
    }
  }

  text(html, formatter) {
    let data = this.state.data ? this.state.data[html] : ''

    if (formatter && data) {
      switch (formatter) {
        case 'list':
          data = data
            .replace('<ul>', '<ul class="fa-ul">')
            .replace(/<li>/g, '<li><span class="fa-li"><i class="fas fa-check-circle"></i></span>')
            .replace(/<\/li>/g, '<hr></hr></li>')
          break
        case 'button':
        case 'header':
          data = data
            .replace(/<p>/g, '')
            .replace(/<\/p>/, '')
          break
        case 'select':
          data = data
            .replace('<ul>', '')
            .replace('</ul>', '')
            .replace(/<li>/g, '<option>')
            .replace(/<\/li>/g, '</option>')
          break
      }
    }

    return Parser(data)
  }

  render() {
    return (
      <div>
        {/* Contact Modal */}
        <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
          <Modal.Body>
            {this.text('contact_form')}
            {this.state.submitDone ? (
              <h5>{this.staticText('contact_form_submit')}</h5>
            ) : (
                <Form onSubmit={() => { }}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{this.staticText('your_name')}</Form.Label>
                    <Form.Control required type="text" placeholder={this.staticText('your_name')} id='formName' onChange={this.setInput} />
                  </Form.Group>

                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{this.staticText('email_address')}</Form.Label>
                    <Form.Control type="email" placeholder={this.staticText('email_address')} id='formMail' onChange={this.setInput} />
                  </Form.Group>

                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{this.staticText('session_date')}</Form.Label>
                    <Form.Control as="select" id='formEvent' onChange={this.setInput} >
                      <option>{this.staticText('select_course')}</option>
                      {this.text('event_list', 'select')}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" >
                    <Form.Label>{this.staticText('message')}</Form.Label>
                    <Form.Control type="text" as="textarea" placeholder="Ihre Nachricht" id='formMessage' onChange={this.setInput} />
                  </Form.Group>

                  <Form.Group controlId="formBasicChecbox">
                    <Form.Check type="checkbox" id='formLegals' onChange={this.setInput} />
                    <span className='legals'>{this.staticText('data-protection-check')}
                      <a className='data-protection' href="/datenschutz" onClick={() => { this.setState({ showModal: false }); this.scrollTo(this.refDataProtection) }}> Datenschutzerklärung </a></span>
                    <label className='legals'>{this.staticText('data-protection-info')}</label>
                  </Form.Group>

                  <Button variant="primary" disabled={this.state.formLegals === 'on' ? false : true} onClick={this.onSubmitForm}>{this.staticText('submit')}</Button> &nbsp;
                  <Button variant="light" onClick={() => this.setState({ showModal: false })}>{this.staticText('close')}</Button>
                </Form>
              )}
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showCourseList} onHide={() => this.setState({ showCourseList: false })}>
          <Modal.Body>
            {this.text('course_modal_title')}
            {this.text('event_list')}
          </Modal.Body>
        </Modal>

        <Navbar bg="light" expand="lg" ref={(ref) => this.reftop = ref} expanded={this.state.navextended} onToggle={() => this.setState({ navextended: !this.state.navextended })}>
          <Navbar.Brand><a href="/"> <img src={logo} width="145px" alt="logo" /></a></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => this.setState({ navextended: !this.state.navextended })} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">{this.staticText('home')}</Nav.Link>
              <Nav.Link href="/live-training" className='blinking text'>{this.text('live_training_text', 'header')}</Nav.Link>
              <Nav.Link href="/#leistungen" onClick={() => this.setAnchor('#leistungen')}>{this.text('services', 'header')}</Nav.Link>
              <Nav.Link href="/#referenzen" onClick={() => this.setAnchor('#referenzen')}>{this.text('references', 'header')}</Nav.Link>
              <Nav.Link onClick={() => this.setState({ showCourseList: true })} >{this.staticText('courses', 'header')}</Nav.Link>
              <Nav.Link onClick={() => this.setState({ showModal: true })}>{this.text('contact', 'header')}</Nav.Link>
              <NavDropdown title={this.state.lang === 'de' ? 'Deutsch' : 'English'} id="basic-nav-dropdown" >
                <NavDropdown.Item onClick={() => this.setLang('de')}>Deutsch</NavDropdown.Item>
                <NavDropdown.Item onClick={() => this.setLang('en')}>Englisch</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Jumbotron style={{ backgroundImage: `url(${imageUrl + (this.state.data ? this.state.data.main_image : '')})` }}>
          <Container>
            <Row>
              <Col>
                <h3 className="page-title">{this.text('page_title')}</h3>
                <p>
                  <Button variant="primary" onClick={() => this.setState({ showModal: true })}>{this.text('cta', 'button')}</Button>
                </p>
              </Col>
            </Row>
            <Carousel slide={true} controls={false} interval={6000} indicators={false} onTouchCancel={false}>
              <Carousel.Item>
                <p className='product-item'>
                  <i>{this.text('slider_1', 'button')} | <a className='product-link' onClick={() => this.setState({ showModal: true })}>{this.text('more', 'button')}</a></i>
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <p className='product-item'>
                  <i >ECS Database management Software | <a className='product-link' href="/management-software">{this.text('more', 'button')}</a></i>
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <p className='product-item'>
                  <i >MS Excel Privat-Nachhilfe bei ECS | <a className='product-link' href="/privat-nachhilfe">{this.text('more', 'button')}</a></i>
                </p>
              </Carousel.Item>
              <Carousel.Item>
                <p className='product-item'>
                  <i >MS Excel Training bei ECS | <a className='product-link' href="/excel-training">{this.text('more', 'button')}</a></i>
                </p>
              </Carousel.Item>
            </Carousel>
          </Container>
        </Jumbotron>

        <Router>
          <div>
            <Route exact path="/" component={this.main} />
            <Route path="/management-software" component={this.product1} />
            <Route path="/privat-nachhilfe" component={this.product2} />
            <Route path="/excel-training" component={this.product3} />
            <Route path="/datenschutz" component={this.dataProtection} />
            <Route path="/impressum" component={this.imprint} />
            <Route path="/live-training" component={this.newZoomMeetings} />
          </div>
        </Router>

        <div className='call-now'>
          <Container>
            <Row>
              <Col lg={12} xs={12}>
                {this.text('call_now')}
              </Col>
            </Row>
            <a className='data-protection' href="/impressum" onClick={() => { this.setState({ showModal: false }); }}>{this.staticText('legal_notice')}</a>&nbsp;&nbsp;
             <a className='data-protection' href="/datenschutz" onClick={() => { this.setState({ showModal: false }); }}>Datenschutzerklärung </a>
            <br /><br /><br />
          </Container>
        </div>
      </div>
    )
  }

  main() {
    return (
      <Container>
        <Row>
          <Col>
            {this.text('about_us')}
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>{this.text('our_services')}</h4>
          </Col>
        </Row>

        <h3 name='services' ref={(ref) => this.ref1 = ref}>{this.state.data ? this.state.data.aio2kd : ''}</h3>

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>
                  {this.text('product_1_short')}
                </Card.Text>
                <Button variant="primary" href="/management-software">{this.text('more', 'button')}</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>
                  {this.text('product_2_short')}
                </Card.Text>
                <Button variant="primary" href="/privat-nachhilfe">{this.text('more', 'button')}</Button>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Text>
                  {this.text('product_3_short')}
                </Card.Text>
                <Button variant="primary" href="/excel-training">{this.text('more', 'button')}</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <h3 name="references" ref={(ref) => this.ref2 = ref}>{this.text('our_references')}</h3>

        <Row>
          <Col>
            {this.text('reference_list')}
          </Col>
        </Row>

        <h3>{this.text('why_ecs')}</h3>

        <Row>
          <Col>
            {this.text('service_list', 'list')}
          </Col>
        </Row>
      </Container>
    )
  }

  newZoomMeetings() {
    return (
      <Container>
        <Row>
          <Col>
          {this.text('live_training')}
          </Col>
        </Row>
      </Container>
    )
  }


  imprint() {
    return (
      <Container>
        <Row>
          <Col>
            {this.text('legal_notice')}
          </Col>
        </Row>
      </Container>
    )
  }

  product1() {
    return (
      <Container>
        <Row>
          <Col> <br />
            {this.text('product_1_long')}
            {this.state.data && this.state.data.product_1_image && <img src={imageUrl + (this.state.data ? this.state.data.product_1_image : '')} width='50%' />}
            <br /><br />
            <Button variant="primary" onClick={() => this.setState({ showModal: true })}>{this.text('more', 'button')}</Button>
          </Col>
        </Row>
      </Container>
    )
  }

  product2() {
    return (
      <Container>
        <Row>
          <Col> <br />
            {this.text('product_2_long')}
            {this.state.data && this.state.data.product_2_image && <img src={imageUrl + (this.state.data ? this.state.data.product_2_image : '')} width='50%' />}
            <br /><br />
            <Button variant="primary" onClick={() => this.setState({ showModal: true })}>{this.text('more', 'button')}</Button>
          </Col>
        </Row>
      </Container>
    )
  }

  product3() {
    return (
      <Container>
        <Row>
          <Col> <br />
            {this.text('product_3_long')}
            <br /><br />
            <Button variant="primary" onClick={() => this.setState({ showModal: true })}>{this.text('more', 'button')}</Button>
          </Col>
        </Row>
      </Container>
    )
  }

  dataProtection() {
    return (
      <Container ref={(ref) => this.refDataProtection = ref}>
        <Row>
          <Col> <br />
            {this.text('data-protection')}
          </Col>
        </Row>
      </Container>
    )
  }
}

export default App;
