const english = {
    'contact': 'contact',
    'your_name': 'Your name',
    'email_address': 'Email address',
    'session_date': 'Ask for a course without obligation',
    'select_course': 'Select a course',
    'message': 'Leave a message',
    'data-protection-check': 'Mandatory field: please tick. I have read the privacy policy and accept it.',
    'data-protection-info': 'By using this form, you consent to the storage and processing of your data through this site. You can revoke this consent at any time!',
    'submit': 'Submit',
    'close': 'Close',
    'courses': 'Courses',
    'home': 'Home',
    'legal_notice': 'Impressum',
    'contact_form_submit': 'Thank you for your message! We will be answering it as soon as possible.'
}

const german = {
    'contact': 'Kontakt',
    'your_name': 'Ihr Name',
    'email_address': 'Email Adresse',
    'session_date': 'Fragen Sie unverbindlich für einen Kurs an',
    'select_course': 'Kurs auswählen',
    'message': 'Hinterlassen Sie eine Nachricht',
    'data-protection-check': 'Pflichtfeld: bitte ankreuzen. Ich habe die Datenschutzerklärung gelesen und akzeptiere diese.',
    'data-protection-info': 'Mit der Nutzung dieses Formulars erklären Sie sich mit der Speicherung und Verarbeitung Ihrer Daten durch diese Website einverstanden. Sie können diese Einverständniserklärung jederzeit widerrufen!',
    'submit': 'Absenden',
    'close': 'Schließen',
    'courses': 'Kurse',
    'home': 'Startseite',
    'legal_notice': 'Impressum',
    'contact_form_submit': 'Vielen Dank für Ihre Anfrage. Wir werden sie so schnell wie möglich beantworten..'
}

export default class StaticText {
    locale = null

    constructor(language) {
        this.locale = language === 'de' ? german : english;
        this.staticText = this.staticText.bind(this)
    }

    staticText(value) {
        return String(this.locale[value])
    }
}
